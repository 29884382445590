import React from 'react';
import { useSpring, animated } from 'react-spring';
import styled from '@emotion/styled';

const AnimatedSvg = styled(animated.svg)`
  fill: var(--color-text);
  width: 2em;
  height: 2em;
`;

export default function Sun({ enableAnimations }) {
  const style = useSpring({
    from: { transform: 'translate(0%, 100%)' },
    to: { transform: 'translate(0%, 0%)' },
    immediate: !enableAnimations,
  });

  return (
    <AnimatedSvg
      style={style}
      viewBox="0 0 29 29"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M14.5 7.23651C10.4948 7.23651 7.23651 10.4955 7.23651 14.5006C7.23651 18.5058 10.4948 21.7648 14.5 21.7648C18.5045 21.7648 21.7635 18.5064 21.7635 14.5006C21.7635 10.4948 18.5045 7.23651 14.5 7.23651Z" />
      <path d="M14.5 5.10133C13.7172 5.10133 13.0828 4.46687 13.0828 3.68472V1.41725C13.0828 0.634455 13.7172 0 14.5 0C15.2828 0 15.9173 0.634455 15.9173 1.41725V3.68472C15.9173 4.46687 15.2822 5.10133 14.5 5.10133Z" />
      <path d="M14.5 23.898C13.7172 23.898 13.0828 24.5325 13.0828 25.3153V27.5821C13.0828 28.3656 13.7172 29 14.5 29C15.2828 29 15.9173 28.3656 15.9173 27.5821V25.3153C15.9173 24.5325 15.2822 23.898 14.5 23.898Z" />
      <path d="M21.1451 7.85428C20.5922 7.30073 20.5922 6.40364 21.1451 5.85009L22.7486 4.24662C23.3015 3.69371 24.1992 3.69371 24.7527 4.24662C25.3063 4.80016 25.3063 5.6979 24.7527 6.2508L23.1493 7.85428C22.5964 8.40782 21.6993 8.40782 21.1451 7.85428Z" />
      <path d="M7.85424 21.1464C7.3007 20.5922 6.4036 20.5922 5.85006 21.1464L4.24658 22.7492C3.69368 23.3021 3.69304 24.2005 4.24658 24.7534C4.80012 25.3063 5.69787 25.3063 6.25077 24.7534L7.85424 23.1493C8.40779 22.5964 8.40779 21.6986 7.85424 21.1464Z" />
      <path d="M23.898 14.5C23.898 13.7172 24.5325 13.0828 25.3153 13.0828H27.5827C28.3655 13.0828 29 13.7172 29 14.5C29 15.2828 28.3655 15.9166 27.5827 15.9166H25.3153C24.5325 15.9166 23.898 15.2828 23.898 14.5Z" />
      <path d="M5.10133 14.5C5.10133 13.7172 4.46687 13.0828 3.68408 13.0828H1.41725C0.634455 13.0828 0 13.7172 0 14.5C0 15.2828 0.634455 15.9166 1.41725 15.9166H3.68472C4.46687 15.9166 5.10133 15.2828 5.10133 14.5Z" />
      <path d="M21.1451 21.1464C21.6986 20.5935 22.5964 20.5935 23.1493 21.1464L24.7527 22.7498C25.3063 23.3021 25.3063 24.2005 24.7527 24.7534C24.1992 25.3063 23.3021 25.3063 22.7486 24.7534L21.1451 23.1499C20.5915 22.5964 20.5915 21.6993 21.1451 21.1464Z" />
      <path d="M7.8543 7.85427C8.40784 7.30073 8.40784 6.40363 7.8543 5.85008L6.25082 4.24725C5.69728 3.69371 4.80018 3.69371 4.24664 4.24725C3.6931 4.80015 3.6931 5.69789 4.24664 6.25079L5.85012 7.85427C6.40366 8.40845 7.30076 8.40845 7.8543 7.85427Z" />
    </AnimatedSvg>
  );
}
