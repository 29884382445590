import React, { useEffect, useState } from 'react';

import styled from '@emotion/styled';
import Crescent from './Crescent';
import Sun from './Sun';

const ShrinkWrapper = styled('div')`
  overflow: hidden;
  ${(props) =>
    props.isShrinking && 'transform: scale(0); transition: transform 400ms;'}
`;

export function AnimatedIcon({ theme, enableAnimations }) {
  const [isShrinking, setShrinking] = useState(false);
  const [shouldRise, setShouldRise] = useState(false);
  const [vestedTheme, setVestedTheme] = useState(theme);

  useEffect(() => {
    if (!enableAnimations) {
      setVestedTheme(theme);
      setShouldRise(enableAnimations);
    } else {
      if (vestedTheme !== theme) {
        setShrinking(true);
        setShouldRise(enableAnimations);
      }
    }
  }, [theme, enableAnimations, vestedTheme]);

  const onHavingShrank = () => {
    setVestedTheme(theme);
    setShrinking(false);
  };

  return (
    <ShrinkWrapper isShrinking={isShrinking} onTransitionEnd={onHavingShrank}>
      {vestedTheme === 'light' ? (
        <Sun enableAnimations={shouldRise} />
      ) : (
        <Crescent enableAnimations={shouldRise} />
      )}
    </ShrinkWrapper>
  );
}
