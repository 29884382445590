import React from 'react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';

import { LAYOUT } from '../constants';
import DarkToggle from './themeHandling/DarkToggle';

const HeaderLink = styled(Link)`
  font-family: 'Montserrat', sans-serif;
  text-decoration: initial;
  color: var(--color-text);
  padding-top: 4px;
  @media only screen and (max-width: 600px) {
    font-size: 1.2em;
  }
  font-size: 1.7em;
  &:hover {
    opacity: 0.7;
  }
`;

const HeroContent = styled('div')`
  display: flex;
  justify-content: space-between;
  padding-top: 0.6em;
  padding: 10px 10px;
  max-width: ${parseInt(LAYOUT.maxWidth) + 80}px;
  margin: auto;
`;

const HeroWrapper = styled('header')`
  position: relative;
  height: 100%;
  margin-bottom: calc(100vw / 8);
`;

const Gradient = styled('div')`
  height: 110px;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
`;

export default function Hero() {
  return (
    <>
      <HeroWrapper>
        <HeroContent>
          <HeaderLink to="/">spilled.online</HeaderLink>
          <DarkToggle />
        </HeroContent>
        <Gradient />
      </HeroWrapper>
    </>
  );
}
