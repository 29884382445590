import React from 'react';
import { Global, css } from '@emotion/react';

import { LAYOUT } from '../constants';

export default function GlobalStyles() {
  return (
    <Global
      styles={css`
        //html {font-size: 100%;} /*16px*/

        body {
          background: var(--color-background);
          /* background-image: linear-gradient(
            var(--color-gradient) 1%,
            transparent 5% 95%,
            var(--color-gradient) 99%
          ); */
          color: var(--color-text);
          line-height: 1.75; // from type-scale
          transition: background-color 0.3s ease;
        }

        * {
          box-sizing: border-box;
          margin: 0;
        }
        h1,
        h2,
        h3,
        h4,
        h5 {
          font-family: 'Montserrat', sans-serif;
          font-weight: 800;
          text-transform: lowercase;
        }

        p,
        ul,
        label {
          font-family: 'Roboto Slab', serif;
          font-size: 1em;
          font-weight: 300;
          margin-bottom: 1em;
        }

        p a,
        ul a {
          color: var(--color-text);
          text-decoration: initial;
          border-bottom: 1px solid var(--color-primary);
          &:hover {
            color: ${LAYOUT.quaternaryPink};
          }
        }

        a.gatsby-resp-image-link {
          border: none;
        }

        ul {
          list-style: none;
        }

        ul li {
          &::before {
            content: '\\2022';
            color: var(--color-primary);
            text-shadow: 0px 0px 3px var(--color-primary);
            font-weight: bold;
            display: inline-block;
            width: 1em;
            margin-left: -1em;
          }
        }

        img[src$='.gif'] {
          display: block;
          margin: 0 auto 2em auto;
          max-width: 100%;
        }

        // typography for smartphones, Major Second scale
        @media only screen and (max-width: 600px) {
          h1 {
            font-size: 1.8em;
          }
          h2 {
            font-size: 1.6em;
          }
          h3 {
            font-size: 1.4em;
          }
          h4 {
            font-size: 1.2em;
          }
          h5 {
            font-size: 1.1em;
          }

          h1,
          h2,
          h3,
          h4,
          h5 {
            margin: 3em 0 1em;
            line-height: 1.3;
          }
        }

        //typography for big screens, Major Third scale
        @media only screen and (min-width: 601px) {
          h1 {
            font-size: 3.052em;
          }

          h2 {
            font-size: 2.441em;
          }

          h3 {
            font-size: 1.953em;
          }

          h4 {
            font-size: 1.563em;
          }

          h5 {
            font-size: 1.25em;
          }
          h1,
          h2,
          h3,
          h4,
          h5 {
            margin: 3em 0 1em;
            line-height: 1.3;
          }

          p,
          ul,
          label {
            font-size: 1.1em;
          }
        }
        h1 {
          margin-top: 0;
        }

        small,
        .text_small {
          font-size: 0.8em;
        }
      `}
    />
  );
}
