import React from 'react';
import styled from '@emotion/styled';

import { ThemeContext } from '../ThemeContext';
import { AnimatedIcon } from './AnimatedIcon';
import { usePrefersReducedMotion } from '../../hooks/usePrefersReducedMotion';

const ToggleButton = styled('button')`
  background-color: initial;
  border: initial;
  cursor: pointer;
  outline: initial;
  &:hover {
    opacity: 0.8;
  }
`;

const DarkToggle = () => {
  const { colorMode, setColorMode } = React.useContext(ThemeContext);
  const prefersReducedMotion = usePrefersReducedMotion();

  const labelText = `Activate ${colorMode === 'light' ? 'dark' : 'light'} mode`;

  if (!colorMode) {
    return null;
  }

  const clickHandler = () => {
    setColorMode(colorMode === 'light' ? 'dark' : 'light');
  };

  return (
    <ToggleButton
      aria-label={labelText}
      title={labelText}
      onClick={clickHandler}
    >
      <AnimatedIcon
        theme={colorMode}
        enableAnimations={!prefersReducedMotion}
      />
    </ToggleButton>
  );
};

export default DarkToggle;
