export const COLORS = {
  text: {
    light: '#4A4B53', // dark gray
    dark: '#FFFFFF', // white
  },
  background: {
    light: '#FFFFFF', // white
    dark: '#1C3144', // dark blue
  },
  primary: {
    light: '#FF9052', // Orange
    dark: '#9CF5F7', // Light blue
  },
  secondary: {
    light: '#9CF5F7', // Light blue
    dark: '#FF9052', // Orange
  },
  gradient: {
    light: '#9CF5F74A', // Light blue 29%
    dark: '#FF90524A', // Orange 29%
  },
};

export const COLOR_MODE_KEY = 'color-mode';
export const INITIAL_COLOR_MODE_CSS_PROP = '--initial-color-mode';

export const LAYOUT = {
  maxWidth: '900px',
  tertiaryRed: '#922D50',
  quaternaryPink: '#B07BAC',
};
