import React from 'react';
import { useSpring, animated } from 'react-spring';
import styled from '@emotion/styled';

const AnimatedSvg = styled(animated.svg)`
  fill: var(--color-text);
  width: 2em;
  height: 2em;
`;

export default function Crescent({ enableAnimations }) {
  const style = useSpring({
    from: { transform: `translate(0%, 100%)` },
    to: { transform: `translate(0%, 0%)` },
    immediate: !enableAnimations,
  });

  return (
    <AnimatedSvg
      style={style}
      viewBox="0 0 28 28"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path d="M26.3975 15.5878C26.1304 15.526 25.8656 15.5972 25.6359 15.7676C24.7846 16.5143 23.798 17.1304 22.7081 17.5488C21.6847 17.966 20.5581 18.1856 19.3614 18.2069C16.6688 18.2548 14.1894 17.2016 12.3963 15.4711C10.6031 13.7406 9.46239 11.3003 9.41449 8.60772C9.39438 7.47751 9.57432 6.37699 9.88899 5.37383C10.2357 4.30359 10.7504 3.33013 11.4337 2.48667C11.7264 2.11569 11.6504 1.58501 11.2795 1.29234C11.0438 1.13027 10.7767 1.06852 10.512 1.13973C7.70003 1.95456 5.27031 3.69364 3.54873 5.98541C1.89303 8.24275 0.94487 11.0195 0.998096 14.0113C1.06256 17.6346 2.58374 20.8995 5.01971 23.2503C7.45568 25.6011 10.7394 27.0058 14.3959 26.9407C17.4542 26.8863 20.2608 25.7723 22.4899 23.9703C24.7516 22.1345 26.3681 19.5453 27.0142 16.6076C27.1388 16.1399 26.8646 15.6792 26.3975 15.5878Z" />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect
            width="26"
            height="26"
            transform="translate(0.770752 1.23331) rotate(-1.01924)"
          />
        </clipPath>
      </defs>
    </AnimatedSvg>
  );
}
